@import '~react-image-gallery/styles/scss/image-gallery.scss';

* {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}
@media (max-width: 900px) {
  .my-space {
    //float: right;
    width: 200px;
    display: flex;
    flex-wrap: wrap;
    align-items: end;
    justify-content: end;
  }
  .my-lk-name {
    font-size: 12px;
    line-height: 12px;
    margin: 13px 10px 0 0;
    color: #8f8f8f;
  }
  .my-layout-container {
    padding: 10px 15px 10px 15px;
  }
  .my-offer-container {
    padding: 10px 15px 10px 15px;
  }
  .my-list-buttons-header {
    display: none;
  }
  .my-list-buttons {
    display: block;
  }
}
@media (min-width: 901px) {
  .my-space {
    display: flex;
    flex-wrap: wrap;
    align-items: end;
    flex-direction: row;
  }
  .my-lk-name {
    margin-top: 15px;
    font-size: 14px;
    line-height: 12px;
    color: #8f8f8f;
    //font-weight: bold;
  }
  .my-layout-container {
    padding: 20px 60px 20px 60px;
  }
  .my-offer-container {
    padding: 20px 60px 20px 60px;
  }
  .my-list-buttons-header {
    display: block;
  }
  .my-list-buttons {
    display: none;
  }
}

.printable {
  font-family: sans-serif !important;
}
.printable * {
  font-family: sans-serif !important;
}

@media (max-width: 600px) {
  * {
    font-size: 12px;
  }
}
