.highlighted-dot {
  width: 8px;
  height: 8px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
  margin-left: 4px;
}

.ant-picker-calendar-mode-switch {
  display: none;
}

.record {
  padding: 2px;
  border-radius: 4px;
  transition: 0.3s ease;

  &:hover {
    background: rgb(203, 203, 203);
  }
}
